.PraticipantDeatils{
    font-size: smaller;
}


.responseDisplay{
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 100%;
        /* padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x); */
    color: var(--bs-card-color);
}


.displayQuestion{
          padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
    color: var(--bs-card-color);
    border-bottom: none !important;
    background-color: var(--bs-card-cap-bg) !important; 
}

.paginationButton {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
