.option li {
    text-align: start;
    font-size: 16px;
}

.Mobilepreview .mobileQues p,
li {
    text-align: start;
    font-size: 12px;
}

.Mobilepreview {
    max-width: 325px;
    margin: auto;
}

.tabpreview {
    max-width: 80%;
    margin: auto;
}

/* surveypreview css */
.containerscrolbar table {
    color: white !important;
}

.containerscrolbar {
    overflow-y: auto;
    height: 110vh;
    color: white !important;
    max-width: 1024px;
}

.containerscrolbar::-webkit-scrollbar {
    display: none;
}

.surveyprevemain>div {
    background-color: #2d2d2db2;
    margin: 20px 0px;
    padding: 20px;
    border-radius: 10px;
}
/* 
.css-13xfq8m-MuiTabPanel-root {
    padding: 15px 40px !important;
} */

/* .rating-prev .css-dqr9h-MuiRating-label, */
.rating-prev .rating-label{
    display: inline-block;
    width: 50px;
    text-align: center;
}
.ques-prev{
    text-transform: capitalize;
}
.checkboxDivs input{
    height: 14px;
    aspect-ratio: 1/1;
}




