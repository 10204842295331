.icon-box {
  margin: 0 auto;
  padding-bottom: 20px;
}
.deletemodal {
  width: 400px;
}
.modal-title,
.modal-footer {
  /* margin: 0 auto; */
  margin-left: auto;
}


.h5Createsurvey {
  margin-bottom: 0%;
}
.modal-body {
  padding-top: 0px;
}

.modal-overlay.show {
  display: block;
}

.modalfordelete {
  background: #dc3545;
  color: white;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.modal-header .close {
  position: absolute;
  top: -2px;
  right: 2px;
}

.red-circle {
  width: 76px;
  height: 76px;
  border-radius: 50%;
  text-align: center;
  line-height: 60px;
  vertical-align: middle;
  padding: 5px;
  font-size: 60px;
  color: red;
  transition: 0.2s;
  background-color: white;
  border: 3px solid red;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
  z-index: 1000; 
  display: none; 
}
/* Modal.css */
@media (max-width: 768px) {
  .modal-content {
    width: 90%;
  }
}

@media (min-width: 768px) {
  .modal-dialog {
    max-width: 800px; /* Adjust the maximum width as needed */
  }
}
@media screen and (max-width: 768px) {
  .deleteMainContainer {
    left: 70px; /* Adjust the value accordingly for smaller screens */
  }
  

}
@media screen and (max-width: 768px) {
  .confirm-button{
    width: 100px !important;
  }

}
@media screen and (max-width: 768px) {
  .cancel-confirm-button{
    width: 100px !important;
  }

}
@media screen and (max-width: 576px) {
  .deleteMainContainer {
    left: 70px; /* Adjust the value accordingly for smaller screens */
  }

  .confirm-button,
  .cancelofDeletModal {
    width: 100px !important;
  }
}
