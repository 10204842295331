@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@200;300;400;500;600&family=Quicksand:wght@400;500&family=Smooch+Sans&display=swap');

/* body{
  overflow: hidden;
} */
.surveyform-bg {
  display: inline-block;
  background-position: center top;
  background-size: cover;
  height: 100%;
  width: 100%;
  position: absolute;
  font-family: 'Quicksand', sans-serif;
  overflow: hidden;
}

.css-z177mx-MuiSvgIcon-root-MuiSelect-icon {
  color: inherit;
}

.css-b0l5nh-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: inherit !important; /* Hover state */
}
.css-b0l5nh-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root .MuiOutlinedInput-notchedOutline {
  border: 1px solid;
}
/* Focus state */
.css-b0l5nh-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: inherit; /* Focus state */
}

.css-b0l5nh-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: inherit !important;
  border: 1px solid;
  border-width: 2px;
}

.inputfeilds:focus,
.inputfeilds {
  width: 100%;
  background: transparent;
  text-transform: capitalize;
  font-size: 24px;
  color: inherit;
  border: none;
  border-bottom: 2px solid;
  transition: border-bottom 0.3s;
  box-shadow: none;
}

.inputfeilds::-webkit-scrollbar {
  width: 10px;
  height: 20% !important;
  background: transparent;
}

.inputfeilds::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background of the scrollbar track */
  border-radius: 10px; /* Rounded corners for the track */
 
}

 

.inputfeilds::-webkit-scrollbar-thumb {
  background: #666;
  border-radius: 20px; /* Rounded corners for the thumb */
}

.inputfeilds::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the thumb on hover */
}

.inputfeilds:focus,
.inputfeilds::placeholder {
  text-transform: capitalize;
  /* font-size: 20px; */
  /* color: rgba(26, 26, 26, 0.576); */
  color: inherit;
}

.welcomescreen {
  opacity: 0;
  transition: visibility 0s, opacity 0.9s linear;
}
.animateimg {
  opacity: 1 !important;
  transition: visibility 0s, opacity 0.5s linear;
  max-width: 250px;
  width: 250px;
  margin: auto;
}

/* Media query for mobile devices in portrait mode to override landscape settings */
@media only screen and (max-width: 767px) and (orientation: portrait) {
  .animateimg {
    margin-top: auto !important;
  }
}

.questionsection {
  color: white;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.questionslider-forward {
  animation: mymove1 0.5s;
  position: absolute;
}

@keyframes mymove1 {
  from {
    top: 0px;
  }

  to {
    top: 350px;
  }
}

.questionslider-backword {
  animation: mymove 0.5s;
  position: absolute;
}

@keyframes mymove {
  from {
    bottom: 0px;
  }

  to {
    bottom: 350px;
  }
}

.btnclass {
  color: black;
  background: white;
}

.btnclass {
  color: black;
  background: white !important;
  font-size: 18px;
  letter-spacing: 2px;
  text-transform: uppercase;
  display: inline-block;
  text-align: center;
  font-weight: bold;
  padding: 0.4em 1em;
  /* border: 3px solid #FF0072; */
  border-radius: 2px;
  position: relative;
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.1);

  /* color: #FF0072; */
  text-decoration: none;
  transition: 0.3s ease all;
  z-index: 1;
}

.btnclass:before {
  transition: 0.5s all ease;
  position: absolute;
  top: 0;
  left: 50%;
  right: 50%;
  bottom: 0;
  opacity: 0;
  content: '';
  /* background-color: #2065D1; */
  z-index: -1;
}

.btnclass:hover,
button:focus {
  color: #fff;
}

.scrollable-container {
  max-height: 100vh; /* Adjust the height as needed */
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%; /* Ensure the container takes full width */
}

.btnclass:hover:before,
button:focus:before {
  transition: 0.5s all ease;
  left: 0;
  right: 0;
  opacity: 1;
}

.btnclass:active {
  transform: scale(0.9);
}

/* .quesAnimate{
  transition:'transform 0.8s ease';  
  opacity: 1;
  transform: translateY(-16%);
} */

.SurveySlack-logo-container {
  margin: auto;
  max-width: 350px;
  
}
.SurveySlack-logo-container p {
  font-size: 1.3rem;
}

.SurveySlack-logo-container img {
  margin: auto;
  display: block;
  max-width: 65%;
}

.callToActionText {
  font-size: 14px !important;
  margin-bottom: 0;
}

.range-sub-value{
  margin-right: 6rem;
}
@keyframes fadeInFromTop {
  from {
    opacity: 0;
    transform: translateX(-750px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInFromBottom {
  from {
    opacity: 0;
    transform: translateX(750px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.question-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: absolute;
}

/* .question {
    padding: 10px;
    margin-bottom: 10px;
    animation: fadeInFromTop 0.5s ease;
    color: #ffffff;
  } */

.fade-in {
  animation: fadeInFromBottom 0.9s ease-out;
}

.optionmarker button {
  /* margin-left: 5px; */
  border: none;
  width: 100%;
}

.optionBlinkcss {
  animation: mymove 0.2s infinite !important;
}

.optionstyle {
  text-align: start;
  font-size: 22px;
  border: none;
  color: rgb(0, 0, 0);
  background-color: rgba(255, 255, 255, 0.1);
  padding: 4px;
  margin-bottom: 5px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  box-shadow: rgb(255 255 255 / 20%) 0px 0px 0px 1px inset;
}
.optionstyleimg {
  text-align: start;
  flex-direction: column-reverse;
  font-size: 22px;
  border: none;
  color: rgb(0, 0, 0);
  background-color: rgba(255, 255, 255, 0.12);
  padding: 4px;
  margin-bottom: 5px;
  border-radius: 4px;
  /* box-shadow: rgba(255, 255, 255, 0.6) 0px 0px 0px 1px inset; */
}

.optionstyle:hover {
  background-color: rgba(255, 255, 255, 0.3);
}
.optionnumber,
.optionstyleimg .optionnumber,
.optionstyle .optionnumber {
  border: 1px solid white;
  border-radius: 6px;
  height: 25px;
  color: rgb(5, 5, 5);
  background-color: rgba(199, 198, 219, 0.67);
  padding: 0px;
}

@keyframes mymove {
  from {
    background-color: rgba(86, 86, 142, 0.442);
  }

  to {
    background-color: rgba(228, 228, 228, 0.94);
  }
}

.scale-button2 {
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  font-size: 18px;
  color: rgb(255, 255, 255);
  border-radius: 4px;
  border: none !important;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(255, 255, 255, 0.6) 0px 0px 0px 1px inset;
}

.ratingstyle span {
  padding: 0 10px;
}

.surveytutle {
  font-size: 32px;
}

@media screen and (max-width: 560px) {
  .MuiSlider-root {
    width: 88% !important;
    margin-left: 3.8% !important;
  }

  .inputfeilds input::placeholder {
    font-size: 22px;
  }
  .surveytutle {
    font-size: 22px;
  }
}

.requiredalert {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
  font-family: inherit !important;
  color: red;
  width: max-content;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.timeselect div div {
  background-color: white;
  border-radius: 5px;
}
.timeselect div:first-child {
  min-width: 100%;
}

.timeselect > div {
  display: flex;
  flex-direction: column;
}
.showquestion {
  display: block;
  width: 100%;
}
.hidequestion {
  display: none;
}

/* first-1 css start */
.survey-Question > div {
  background-color: #f5f5f51f;
  padding: 20px;
  margin: 10px 0;
  border-radius: 15px;
}
.input-feild {
  margin-top: 8px;
  width: 100%;
  border-radius: 10px;
  background: white;
}
.input-feild-new {
  margin-top: 8px;
  width: 100%;
  border-radius: 10px;
  /* background: white; */
}

.optionstyle-new {
  font-size: 1rem !important;
}

.optionradiobtn .form-check-input:checked[type='radio'] {
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  background-image: unset !important;
}

.optionradiobtn input {
  border-radius: 5px !important;
  width: 100%;
  background-color: #ffffff24;
  height: 2.5em;
  margin: 0;
  border: none;
  cursor: pointer;
  pointer-events: auto;
}

.optionradiobtn .form-check-input:focus {
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 3px rgb(243 243 243 / 32%);
}

.optionradiobtn .form-check-input:checked {
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  background-color: #0d6dfd00 !important;
  border: 3px solid var(--button-color) !important;
  border-color: #ffffff;
  border-radius: 5px;
  box-shadow: none;
}

.optionradiobtn .form-check-input:hover {
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border: 1px solid var(--button-color);
  
}

.optionradiobtn {
  cursor: pointer;
  position: absolute;
  width: 100%;
}

.optionradiobtn .form-check-input:checked[type='checkbox'] {
  
  background-image: unset !important;
}
.img-selection-question input {
  height: 110px;
  background-color: transparent;
  aspect-ratio: 1 / 1;
}
.labels {
  display: none;
  position: absolute;
  bottom: -31px;
  left: 10px;
  white-space: nowrap;
  font-size: 17px;
  text-align: center;
  /* color: inherit; */
}
/* .lablebtn{
  position: relative;
} */

.lablebtn:hover + .labels {
  display: block;
}
.rating-container {
  flex-wrap: wrap;
  overflow-x: auto;
  white-space: nowrap;
}

.rating-item {
  /* margin: 5px; */
  display: inline-block;
}


@media (max-width: 768px) {
  .rating-container {
    justify-content: flex-start;
  }
  .landscapepreviewforwelcomescreen .responsive-sub{
  top: 15vh;
    position: relative;
    margin-top: 8vh;  
}

.welcomeMSG {
  padding: 0px 10px;
   
}
.landscapepreviewforwelcomescreen{
  height: 100%;
}
 

.landscapepreviewforwelcomescreen .responsivebtn-sub {

  position: relative;
  top: 45vh;
}

    .cust-text {
      padding-left: 16px;
      padding-right: 16px;
      
    }
    
 
}

@media   (max-width: 460px) {
  .landscapepreviewforwelcomescreen{
    height: 100%;
  }
  .landscapepreviewforwelcomescreen .responsivebtn-sub {
    position: relative;
    top: 40vh;
  }
}

@media   (max-width: 420px) {
  .landscapepreviewforwelcomescreen{
    height: 100%;
  }
  .landscapepreviewforwelcomescreen .responsivebtn-sub {
    position: relative;
    top: 28vh;
  }
}


@media   (max-width: 380px) {
  .landscapepreviewforwelcomescreen{
    height: 100%;
  }
   
  
  .landscapepreviewforwelcomescreen .responsivebtn-sub {
  
    position: relative;
    top: 28vh;
  }
}

@media only screen and (max-width: 767px) and (orientation: landscape) {
  .landscapepreview {
    display: flex !important;
  }
  .welcomeMSG {
    padding: 10px;
    margin: auto;
  }
}

@media only screen and (max-width: 767px) and (orientation: landscape) {
  .landscapepreviewforwelcomescreen {
    display: flex !important;
  }
  .landscapepreviewfordivwelcomescreen {
    padding: 10px;
    margin: auto;
  }
}
.table-responsive {
  overflow-x: auto;
  max-width: 100%;
}

.matrix-table {
  width: auto;
  table-layout: auto;
  border-collapse: collapse;
}

.sticky-header {
  position: sticky;
  top: 0;
  background-color: #f0f0f0;
  z-index: 2;
}

.sticky-row {
  position: sticky;
  left: 0;
  /* background-color: #ffffff; */
  z-index: 3;
  border-right: 1px solid #ddd;
}

.sticky-column {
  position: sticky;
  z-index: 1;
}

.sticky-header,
.sticky-row,
.sticky-column {
  text-align: center;
  padding: 8px;
}

.striped-table tbody tr:nth-of-type(even) {
  background-color: inherit; /* Inherit the background color from styleObject */
}

.striped-table tbody tr:nth-of-type(odd) {
  background-color: inherit; /* Inherit the background color from styleObject */
}
.even-row {
  background-color: rgb(0 0 0 / 5%);
}
.NextPrevbtn {
  justify-content: space-between !important;
  position: fixed;
  /* right: 20px; */
  width: inherit;
  bottom: 10px;
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  /* gap: 10px; */
  padding: 10px;
  justify-content: center; /* Ensure buttons are centered */
}

.NextPrevbtn > * {
  /* flex: 1; */
  max-width: 150px; /* Limit maximum width of each button */
}

/* Media query for screens smaller than 768px (typical mobile devices) */
@media (max-width: 767px) {
  .NextPrevbtn {
    width: calc(100% - 40px);
    max-width: '-webkit-fill-available';
    padding: 10px;
    bottom: 0px;
    z-index: 100;
    justify-content: flex-end;
  }
  .setbuttons {
    margin-bottom: 50px;
  }
}

.textlinear {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
}

@media (max-width: 768px) {
  .textlinear {
    /*display: block;  Change to block display for mobile devices */
    display: flex;
    padding-top: 1rem;
  }

  .textlinear span {
    display: block; /* Ensure each span displays on a separate line */
    margin-bottom: 10px; /* Optional: Add margin between spans for spacing */
  }
}

@media screen and (max-width: 1200px) {
  .MuiSlider-root {
    width: 95%;
    margin-left: 2.5%;
  }
  .range-wrapper-value{
    span{

    }
  }
}

@media (max-width: 767px) {
  .lineargap {
    /* gap: 5%; */
  }
}
