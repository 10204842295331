.modal-content{
    border-radius: 20px;
    margin: auto;
}

#editquestionpopup,
#createquestionpopup {
    max-width: 96% !important;
}
.bgButton{
  background-color: #198754;
    color: white;
    border-radius: 5px;
}

.dropdownclass {
    /* padding: 0px 5px !important; */
    margin-top: 10px !important;
}

.inputvaluestyle input {
    font-size: 16px !important;
}

.inputvaluestyle input::placeholder {
    font-size: 16px !important;
    white-space: nowrap;
}

.over {
    transform: scale(1.01);
    border: 3px solid gray;
}

/* ------------multiplechoics.js -css-------------- */
.mcqdata{
   min-width: 120px;
   max-width: 200px;
}

.accordion-button::after{
    margin-left: 20px;
    color: black !important;
}

.accordion-button{
    border-radius: 0px !important;
    border: 1px solid  #0d6efd !important;
    color: black !important;
}

.option-image {
    width: 20px; /* Adjust the width as per your design */
    height: auto;
    margin-right: 10px; /* Adds some spacing between the image and text */
  }
  

@media only screen and (max-width: 425px) {
    .linear-chip{
        width: 40px !important;
    }
    .rangeslider_style{
        width: 20% !important;
        font-size: 0.7rem;
    }
    .css-1yrymlm-MuiTypography-root {
        font-size: 0.8rem;
    }
  }
@media only screen and (max-width: 768px) {
    .dropdown-component div
    {
    width: 100%;
    margin:5px 0px !important;
    }
  }
@media only screen and (max-width:1024px) {
    .ques-prev-div 
    {
        width: 100% !important;
        max-width: 100% !important;
    }
  }

.preview{
    color: black;
}
.accordion-button.preview{
    color: black;
}
  .ques-prev-div  ::-webkit-scrollbar {
  height: 10px;
}

/* Track */
.ques-prev-div  ::-webkit-scrollbar-track {

  border-radius: 10px;
}
 
/* Handle */
.ques-prev-div  ::-webkit-scrollbar-thumb {
  background: rgb(209, 209, 209); 
  border-radius: 10px;
}

/* Handle on hover */
.ques-prev-div  ::-webkit-scrollbar-thumb:hover {
  background: #b30000; 
}
