.opensurveyModal .modal-content {
    border-radius: 0;
}


/* dashboard/survey */
/* survey lising css */
.tootip-icon {
    color: #3e3e3e4f;
    font-size: 16px;
}

a {
    text-decoration: none;
}

.MuiTypography-root:hover .tootip-icon {
    color: #2c2c2c7e;
}

.rediect_class {
    display: flex;
    justify-content: space-between;
    position: absolute;
    right: 9px;
    top: 8px;
}


.titlecss:hover {
    color: #000;
}

.surveyheading {
    overflow: hidden;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.css-1j6nl8k-MuiTypography-root-MuiLink-root {
    height: 25px !important;
}

.surveyDescription div {
    padding-bottom: 24px !important;
} 

.css-154lg22-MuiPaper-root-MuiDialog-paper {
    width: 100%;
}

.shareModal div:first-child {
    background-color: #aeaebd3e !important;
}

.surveycardui:hover {
    box-shadow: 0px 5px 10px -4px black;
}

.css-1je2b1o {
    width: 60%;
    border: none;
    padding: 20px 35px;
    border-radius: 4px;
}
@media screen and (max-width: 768px) {
    .css-1je2b1o {
        width: 90%;
    }
  }
#url-input:focus-visible {
    outline: none
}

@media screen and (max-width:425px) {
    .css-1je2b1o {
        width: 95%;
    }
}

.starrating .css-dqr9h-MuiRating-label {
    font-size: 2.1rem !important;
    max-width: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
}

.surveyPreview .css-e9crry-MuiInputBase-input-MuiOutlinedInput-input {
    color: white;
}

.surveyPreview .css-bkzwk2-MuiStack-root {
    padding-top: 0px;
}

.cratesurveyModal>div {
    padding: 0 0 20px 0;
    position: relative;
}

.createsurveyalert {
    color: red;
    font-size: 14px;
    position: absolute;
    bottom: -4px;
    left: 0px;
}

.image-aspect-ratio {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
  }
  .image-aspect-ratio::before {
    display: block;
    content: "";
  }


  .image-aspect-ratio-1by1::before {
    padding-top: 100%;
  }
  .image-aspect-ratio img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    -o-object-fit: cover;
       object-fit: cover;
  }

  
  /* YourComponent.css */
/* skeleton css */
.your-card {
    width: 100%;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: absolute;
    z-index: 999;
  }
  
  .your-card__content {
    padding: 20px;
  }
  
  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }
  
  .your-card__image,
  .your-heading,
  .your-paragraph {
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1s shine linear infinite;
  }
  
  .your-card__image {
    height: 200px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .your-heading {
    height: 30px;
  }
  .css-clpea3 {
    position: relative;
    padding-top: calc(80%* 3 / 4);
}
  

.css-z1av0t-MuiCardContent-root {
    padding: 21px 15px 21px 15px !important;
}

.date input{
    padding: 8.5px;
}