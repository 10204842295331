:root {
  --modal-border-radius: 15px;
  --box-border-radius: 10px;
  --extralight-gray: rgba(238, 238, 238, 0.521);
  --custum-modal-width: 800px;
}

.editpass {
  background-color: transparent;
  border: navajowhite;
  position: absolute;
  right: 0px;
  bottom: 0;
}

.editpass .editicon {
  font-size: 15px;
  padding: 1px;
  color: black;
}

.editpass :hover {
  background-color: rgb(214, 214, 214);
  border-radius: 5px;
}

/* .show {
  z-index: 999 !important;
} */

.css-1y6sui9,
.MuiPaper-elevation {
  z-index: 7 ;
}

.checkbtn {
  background-color: #d7d7d7;
  border: navajowhite;
  border-radius: 5px;
  padding: 8px 15px;
}

.greentic {
  color: rgb(19, 150, 19);
  font-size: 25px;
  position: absolute;
  top: 5px;
  right: 28px;
}


/* survey card css  */
.surveytitle {
  background-color: #ebebeb;
  white-space: nowrap;
}

.content_list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(290px, 0fr));
  grid-gap: 40px;
}



.surveycardhead {
  /* padding: 15px; */
  background-color: rgb(255, 255, 255);
}

.surveycardhead h4 {
  color: rgb(71, 71, 71);
}



.survey_inactive {
  background-color: rgb(107 225 243 / 20%);
}

.survey_active {
  background-color: rgb(240 240 240);
}

/* .surveymain {
  font-family: Public Sans, sans-serif;
  border-radius: 24px;
  border: 1px solid rgb(223, 223, 223);
  overflow: hidden;
} */

.alers-modal .btn-close {
  position: absolute;
  right: 11px;
  z-index: 7;
}

.alers-modal .modal-content,
.alers-modal .MuiPaper-elevation {
  border-radius: 0px;
}

/* questiontab/questionlist.css */
.questiontab {
  padding: 20px;
  border: 1px solid gray;
  border-style: dashed;
  color: lightgray;
  border-radius: 10px;
}

.questiontab:hover {
  background-color: #adff2f1f;
}

/* mcq-question css */

.detele-icon {
  padding: 10px 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f2f2f2;
  border-radius: 9px;
  margin: 6px 0px;
  width: 100%;
  position: relative;
}

.detele-icon svg {
  color: gray;
  font-size: 20px;
}

.question-listing {
  background-color: #dddddd;
  border-radius: 10px
}

.form-check .form-check-input {
  margin-left: 0px;
}

.range-select:hover {
  background-color: var(--extralight-gray) !important;
  border-radius: var(--box-border-radius);
}

.css-fi1bin {
  border-radius: var(--box-border-radius);
}

.modal.show .modal-dialog {
  max-width: var(--custum-modal-width) ;
}

.checkbox .form-check-input,
 .mcq-question .form-check-input{
  margin-right: 6px;
}

.gridcheckbox .form-check,
.gridmcq .form-check,
.linearscale .form-check{
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}

.checkbox .form-control{
  margin-right: 5px;
}

.gridmcq .form-control{
 width: 30px;
}

.dropdown-component .MuiOutlinedInput-input {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.dropdown-component .dropdown-select{
justify-content: space-between;
}

.img-uploader{
  max-width: 200px;
  width: 200px;
}

.conferm_passowrd:focus{
  color: unset;
}

.survey-box:hover {
  background-color: #e4f1ff; /* Change to the desired background color */
  color: #1c6c5e; /* Change to the desired text color */
  border-color: #1c6c5e; /* Change to the desired border color */
}

.iconcontainer {
  display: flex;
  margin-bottom: 25px;
}
@media (min-width: 1200px) {
  .headingofTab {
    font-size: 1.5em; /* Corrected syntax for 'font-size' property */
  }
  .iconOfTab {
    font-size: 30px;
  }
}
@media (min-width: 900px) {
  .headingofTab {
    font-size: 1.5em; /* Corrected syntax for 'font-size' property */
  }
  .iconOfTab {
    font-size: 30px;
  }
}
@media (min-width: 600px) {
  .headingofTab {
    font-size: 1.25em; 
  }
  .iconOfTab {
    font-size: 25px;
   
  }
}
@media (max-width: 600px) {
  .iconcontainer {
    flex-wrap: nowrap; 
  }
  .headingofTab {
    font-size: 1.25em; 
  }
  .iconOfTab {
    font-size: 25px;
    margin-left: 10px;
  }
}

.headingofTab{
  font-weight: 700;
  font-family: "Public Sans", sans-serif;
  padding-left: 10px;
  
}

/*  */

.input-container {
  display: flex;
  align-items: center;
}

.input-container input {
  flex-grow: 1;
  margin-right: 8px; /* Adjust the margin as needed */
  padding: 8px; /* Add padding to the input */
}

.copy-button {
  padding: 8px 12px; /* Adjust the padding as needed */
  background-color: #1c6c5e; /* Add your desired button color */
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.copy-button:hover {
  background-color: #1c6c5e; /* Add your desired hover color */
}
/* Define media query for mobile resolutions */
@media screen and (max-width: 767px) {
  .copy-button {
    margin-top: 15px; 
    margin-right: auto;
    /* Apply margin from the top for mobile devices */
  }
}




/* .iconcontainer {
  display: flex;
  margin-bottom: 25px;
} */

.copy-button{
  height: 45px;
  width: 100px;
}

.embedTextarea{
  width: 100%;
  height:100px;
  color: #515151;
  border: #d9d9d9 solid thin;
}
.embediFrame{
  margin-top: 50px;
}

.input-container {
  position: relative;
}

.control-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #f2f2f2;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

form {
 
  /* margin: 0 auto; */
  padding-top: 8px;
}

.value-button {
  display: inline-block;
  border: 5px solid #dddddd4a;
  margin: 0px;
  width: 40px;
  height: 41px;
  text-align: center;
  vertical-align: middle;
  padding: 3px 0;
  border-radius: 3px;
  background: #60a298;
  color: white;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.navlink:hover{
  color: #105d4f;
}

.bgButton{
  background-color: #34897a;
    color: white;
    border-radius: 5px;
}
.bgButton:hover{
  background-color: #1b7967;
    color: white;
    border-radius: 5px;
}
.bgCloseButton{
  background-color: #747979;
    color: white;
    border-radius: 5px;
}
.bgCloseButton:hover{
  background-color: #586361;
    color: white;
    border-radius: 5px;
}

.value-button:hover {
  cursor: pointer;
}

form #decrease {
  margin-top: -4px;
  border-radius: 8px 0 0 8px;
}

form #increase {
  margin-top: -4px;
  border-radius: 0 8px 8px 0;
}

form #input-wrap {
  margin: 0px;
  padding: 0px;
}

input#number {
  text-align: center;
  border: none;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  margin: 0px;
  width: 100px;
  height: 40px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.custom-accordion-summary {
  transition: background-color 0.3s;
}

.custom-accordion-summary.expanded {
  background-color: #f0f0f0;
  position: relative;
}

.custom-accordion-summary:focus,
.custom-accordion-summary:active {
  background-color: #f0f0f0;
  position: relative;
}

.custom-accordion-summary:after {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #ccc;
}

#input-direct-link{
  border-radius: 5px;
  
  /* Muted border */
  border: 1px solid #ccc;
  
  /* Padding for better appearance */
  padding: 8px;
}

#input {
  /* Rounded corners */
  border-radius: 5px;
  
  /* Muted border */
  border: 1px solid #ccc;
  
  /* Padding for better appearance */
  padding: 8px;
}

#input:hover,
#input:focus {
  /* Black border on hover or focus */
  border-color: #000;
}

#width{
  width: 100px;
  height: 41px;
  text-align: center;
  border: lightgray solid;
  border-radius: 5px;
}


#height{
  width: 100px;
  height: 41px;
  text-align: center;
  border: lightgray solid;
  border-radius: 5px;
}

.input-container {
  position: relative;
}

.editable-input {
  width: 100%;
  padding-left: 40px;
  box-sizing: border-box;
}

.edit-icon {
  position: absolute;
  top: 50%;
  right: 110px; /* Adjust the right position based on your design */
  transform: translateY(-50%);
}

@media (max-width: 600px) {
  .input-container {
    flex-direction: column;
  }

  .edit-icon {
    position: absolute;
    top: 10px;
    right: 10px; /* Adjust the right position based on your design */
    transform: translateY(0);
  }
}
.hidden {
  display: none;
}

