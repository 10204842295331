.auth-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth {
  width: 80%; /* Adjust the width as needed */
  max-width: 400px; /* Limit the maximum width for larger screens */
  padding: 20px;
}
/* 
  input:-webkit-autofill,
  .css-e9crry-MuiInputBase-input-MuiOutlinedInput-input:-webkit-autofill {
    transition: background-color 50000s ease-in-out 0s !important;
  }
  
  input:not(:-webkit-autofill),
  .css-e9crry-MuiInputBase-input-MuiOutlinedInput-input:not(:-webkit-autofill) {
    animation-name: onAutoFillStart !important;
    animation-name: onAutoFillStart !important;
    transition: background-color 50000s ease-in-out 0s !important;
    background-color: green;
  }
   */
/* input:-webkit-autofill,
.css-1e38844-MuiFormLabel-root-MuiInputLabel-root {
  transform: translate(14px, 9px) scale(1);
} */
/* 
input:not(:-webkit-autofill),
.css-1e38844-MuiFormLabel-root-MuiInputLabel-root {
  transform: translate(14px, -9px) scale(0.75);
} */

/* Responsive styling */
@media screen and (max-width: 768px) {
  .auth {
    width: 90%; /* Adjust the width for smaller screens */
  }
}

/* Additional media queries for smaller screens */
@media screen and (max-width: 480px) {
  .auth {
    padding: 10px;
    border-radius: 0; /* Remove border-radius for smaller screens */
  }
}
