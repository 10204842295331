.participant-list-container {
  width: 100%;
  overflow-x: auto; 
}

.content-list {
  width: 100%;
  border-collapse: collapse;
}

/* Media query for small screens */
@media screen and (max-width: 768px) {
  .participant-list-container {
    overflow-x: scroll;
  }
}
@media screen and (max-width: 480px) {
  .participant-list-container {
    overflow-x: auto; 
  }
}

.content-list td,
.content-list th {
  padding: 10px;
  background-color: #f5f5f5;
  color: #6c757d !important;
}

.content-list th {
  font-weight: bold;
}

.content-list tr{
  background-color: #f5f5f5;
}

.participant-item-location {
  float: right;
}

.geo-country {
  font-weight: bold;
}

.tech-version {
  font-size: smaller;
  margin-left: 5px;
}

nav ul {
  list-style-type: none;
  padding: 0;
}

nav ul li {
  display: inline;
  margin-right: 10px;
}

.email-container {
  display: grid;
}

.col1 {
  font-weight: bolder;
}

.col3,
.col4,
.col5,
.col6 {
  text-align: center;
}

.icon-eye,
.icon-trash {
  width: 16px;
  height: 16px;
}

/* Adjustments for small screens */
@media screen and (max-width: 600px) {
  .content-list td,
  .content-list th {
    padding: 5px;
  }

  .content-list th {
    font-size: 0.8em;
  }

  .content-list td {
    font-size: 0.9em;
    text-align: left;
  }

  .col1,
  .col3,
  .col4,
  .col5,
  .col6,
  .col7 {
    text-align: left;
  }

  .participant-item-location {
    float: none; /* Revert float for location */
  }

  .participant-item-email,
  .participant-item-location {
    display: block; /* Display email and location on separate lines */
    margin-bottom: 5px;
  }

  .icon-eye,
  .icon-trash {
    display: none; /* Hide icons on small screens */
  }
}


.icon-link {
  color: #6c757d ;
  text-decoration: none; 
}

.icon-link:hover {
  color: #2065D1; 
}

.icon-link:active {
  color: #2065D1; 
}
