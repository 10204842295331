/* ResultStyles.css */

.tabsContainer {
    background: #f0f0f0;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .tab {
    text-transform: none;
    min-width: auto;
    font-weight: bold;
    font-size: 14px;
    color: #555;
  }
  
.apexcharts-legend{
    display: none!important;
}

