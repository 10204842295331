.Q_typesection {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: space-between;
    gap: 14px;
}

.deletequestion {
    font-size: 1rem;
    padding: 7px;
}
.Question-type {
    padding: 2px 6px;
    font-size: 13px;
    border-radius: 18px;
    padding: 0px 5px;
    font-size: 12px;
}

@media screen and (max-width:425px) {
    .tab-css .MuiTabs-flexContainer {
        display: flex;
        justify-content: center;
    }

    .tab-css button {
        font-size: 0.7rem;
    }

    .deletequestion {
        font-size: 1rem;
        padding: 7px;
    }

    .Question-type {
        padding: 2px 6px;
        font-size: 13px;
    }

    #question-listpge .question-card .Q_typesection {
        align-items: end;
    }

    .createQuebtns button {
        font-size: 0.8rem;
    }
}