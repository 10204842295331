.form-control {
    border-radius: 5px !important;
}


#profile-upload {
    /* background-image: url(''); */
    background-size: cover;
    background-position: center;
    height: 150px;
    width: 150px;
    border: 1px solid #bbb;
    position: relative;
    border-radius: 250px;
    overflow: hidden;
}

#profile-upload:hover input.upload {
    display: block;
}

#profile-upload:hover .hvr-profile-img {
    display: block;
}

#profile-upload .fa {
    margin: auto;
    position: absolute;
    bottom: -4px;
    left: 0;
    text-align: center;
    right: 0;
    padding: 6px;
    opacity: 1;
    transition: opacity 1s linear;
    -webkit-transform: scale(.75);


}

#profile-upload:hover .fa {
    opacity: 1;
    z-index: 999;

    -webkit-transform: scale(1);
}

#profile-upload input.upload {
    z-index: 1;
    left: 0;
    margin: 0;
    bottom: 0;
    top: 0;
    padding: 0;
    opacity: 0;
    outline: none;
    cursor: pointer;
    position: absolute;
    background: #ccc;
    width: 100%;
    display: none;
}

#profile-upload .hvr-profile-img {
    width: 100%;
    height: 100%;
    display: none;
    position: absolute;
    vertical-align: middle;
    position: relative;
    background: transparent;
}

#profile-upload .fa:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0px;
    background: rgba(0, 0, 0, 0.3);
    z-index: -1;
    transition: height 0.3s;
}

.image-preview {
    position: absolute;
    top: 0;
}


#profile-upload:hover .fa:after {
    height: 100%;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .BiLoader {
    font-size: 22px;
    animation: spin 2s linear infinite; /* Apply the animation to the loader icon */
  }