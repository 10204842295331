/* responsive css strat 360px productsort.js*/
@media screen and (max-width:415px) {
    .surveysort button {
        height: 30px;
        white-space: nowrap;
    }
}

.surveysort {
    align-items: center;
}

.hover-effect {
    transition: transform 0.2s; /* Add a smooth transition effect for the transform property */
}

/* Define the hover effect */
.surveycardui:hover .hover-effect {
    transform: scale(1.04); /* Increase the size of the image on hover */
}