.tableofShortAns {
    border-collapse: separate;
    border-spacing: 0 10px; /* Adjust the second value (vertical spacing) as needed */
    margin: auto;   
    width: 90%;
}

.tableofShortAns thead {
    border-bottom: 1px solid #ddd;
}

.tableofShortAns tr {
    border-bottom: none !important; /* Add !important to ensure this style takes precedence */
}

.tableofShortAns td,th{
    padding: 0px;
}

.tableofLongAns {
    border-collapse: separate;
    border-spacing: 0 10px; /* Adjust the second value (vertical spacing) as needed */
    margin: auto;   
    width: 90%;
}

.tableofLongAns thead {
    border-bottom: 1px solid #ddd;
}

.tableofLongAns th,td {
    padding: 10px;
}

.tableofLongAns tr {
    border-bottom: none !important; /* Add !important to ensure this style takes precedence */
}

.progress{
    height: 30px;
    margin-bottom: 10px;
    width: 100%;
  
}
.progress-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px; /* Adjust margin between rows as needed */
  }
.headprogress{
    width: 100%;
    padding: 25px;
}

.multipleanserdisplay{
    
    align-items: center;
}

.progress-bar{
    color: black;
    text-align: left;
    padding-left: 5px;
    overflow: visible;
    
}



.linearScaleHeading{
    display: flex;
    align-items: baseline;
}
.rangeSlider{
    padding: 50px;
}
.centeredChartContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .progressBarWithImg {
    display: flex;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 25px;
    padding: 20px;
  }

  .progresswithImg{
    width: 100%;
  }

  .imgForProgressBar{
    height: 90px;
    margin-right: 10px;
  }

  .progressImg{
    display: flex;
    align-items: center;
  }

.clearfix{
    display: block;
    border-top: 1px solid #dee1e8;
    padding: 20px;
    text-align: end;
    clear: both;
    background: #f0f0f0;
    border-radius: 3px;
}

.date-time-container{
    padding: 30px;
}

.matrixMcqChart{
    display: flex;
}

